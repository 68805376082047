.container_annonce {
    padding: 0 60px 0 40px !important;
    margin-bottom: 40px;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 350px){
        padding: 0 20px!important;
    }

    .header_annonce{
        position: relative;

        .annonce_image_boat {
            border-radius: 50%;
            height: 24vw;
            width: 24vw;
            max-width: 300px;
            max-height: 300px;
            background-size: cover!important;
            margin: 20px auto 20px auto;
            position: relative;
            min-width: 200px;
            min-height: 200px;
            z-index: 1;
        }
        .line_under_image {
            position: absolute;
            top: 50%;
            width: 100%;
            margin: 0 auto;
            border-color: $back_blue;
            opacity: 0.6;
        }
    }
    .first_main_title {
        margin: 20px 0 5px 0!important;
        font-weight: 600;
    }
    .annonce_date {
        text-align: center;
        text-transform: uppercase;
        color: $font_blue!important;
        letter-spacing: 1px;
        margin-bottom: 40px;
    }
    .container_information {
        .picto_information_circle {
            width: 50px;
            margin: 0 auto;
    
            img {
                border: solid 1px $font_blue;
                border-radius: 50%;
            }   
        }
        h4 {
            color: $font_blue!important;
            font-size: 18px;
            text-align: center;
            margin: 5px 0 15px 0;
            font-weight: 600;
        }
        ul {
            padding: 0 10%;
            color: $font_blue!important;
            line-height: 25px;
        }
    }
    .separator_annonceur {
        margin: 40px auto;
    }
    .container_about_client {
        margin-bottom: 60px;

        .container_info_client {
            min-height: 100px;
            margin-top: 40px;

            .img_info_client {
                background-size: cover!important;
                height: 100px;
                border-radius: 10px;
            }
            ul {
                color:$font_blue!important;

                a{ 
                    color: $font_blue;
                    transition: color 0.5s;
                }
                a:hover{ 
                    color: $font_turcoise;
                    transition: color 0.5s;
                }
            }
        }
    }
    .container_devis_button {
        text-align:center;
        margin-bottom: 50px;

        .disabled {
            height: auto;
            line-height: 20px;
            padding: 20px;
        }
        .proposal {
            background-color: $back_blue!important;
        }
        .proposal:hover {
            background-color: $back_turcoise!important;
        }
    }
}

//mes annonces
.container_own_annonce {
    .container_annonce_image {
        height: 260px;
        background-size: cover!important;
        padding: 0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        .filter_annonce_image {
            background-color: $back_blue;
            height: 100%;
            width: 100%;
            opacity: 0.8;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
    }
    .container_annonce_info {
        height: 260px;
        padding: 20px!important;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        .container_annonce_info_top {

            .container_info_name {
                font-size: 2rem;
                font-weight: 600;
                color: $font_blue;
            }
            .container_annonce_info_header {
                font-size: 1rem!important;
                font-weight: 400!important;
                color: $font_blue;

                p {
                    margin: 0;
                    
                    .material-icons {
                        vertical-align: middle;
                        font-size: 18px;
                    }
                }
            }
        }
        .container_annonce_info_main { 
            span {
                display: inline;

                em {
                    font-style: inherit;
                    font-weight: 600;
                }
            }
            span:after {
                content: " |";
            }
            span:last-child:after {
                content: "";
            }
        }
        .container_annonce_edit {
            display: flex;
            justify-content: space-between;
            padding: 0;

            @media screen and (max-width: 992px) {
                text-align: center;
                
                .row {
                    width: 100%!important;
                    margin-bottom: 0!important;

                    .col {
                        margin-top: 10px!important;
                        margin-bottom: 10px!important;
                    }
                }
                
            }
            .button_display_estimate {
                height: auto;
                line-height: 16px;
                @media screen and (max-width: 992px) {
                    height: inherit!important;
                    line-height: 32.4px!important;
                }
            }
        }

    }
}

//edit 
.edit_annonce {
    input[type=text]:not(.browser-default):focus:not([readonly])+label {
        color: $font_blue;
    }
    input[type=text], textarea.materialize-textarea, input[type=date] {
        color: $font_blue!important;
    }
    span:before {
        border-right: 2px solid $font_blue!important;
        border-bottom: 2px solid $font_blue!important;
    }
}

.offre_et_devis {
    .container_information_annonce {
        padding: 0!important;
        margin-bottom: 23px!important;
        color: $font_blue!important;
    
        .annonce_subtitle {
            margin: 0;
            padding: 10px 0;
            font-size: 15px;
            font-weight: 700;
            line-height: 20px;
            color: $font_blue!important;
            text-transform: uppercase;
        }
        .annonce_information_container {
            padding: 10px 0;

            .annonce_information_container_line { 
                margin-bottom: 5px;

                .annonce_information {
                    width: 100%;
                    display: inline-block;
                    color: $font_blue;
                }
                .annonce_information_data {
                    display: inline-block;
                    width: 100%;
                    text-align: end;
                    color: $font_blue;
                    border-bottom: 1px solid $back_blue;
                    padding-bottom: 5px;

                    @media only screen and (max-width: 992px) {
                        width: 100%;
                    }
                }
            }
        }
    }
}

#ad {
    label.required {
        width: 100%!important;
    }
}