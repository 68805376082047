html, body {
    height: 100%;
}
body {
    font-family: $font-stack;
    background-color: #FFF;
}
body {
    display: flex;
    flex-direction: column;
}
html {
    scroll-behavior: smooth;
}