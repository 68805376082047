/*----------------
------Effect------
-----------------*/
.is-hidden {    
    opacity: 0;
    -webkit-transition: all 0.6s;
    transition: all 0.6s;
    visibility: hidden;
}
.is-visible {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.is-visible-b {
    opacity: 1;
    -webkit-transition: all 0.6s;
    transition: all 0.6s;
    background-color: #000;
}

/*----------------------
------Header Front------
----------------------*/
.navbar-fixed {
    height: 90px;
    
    .nav-content {
        height: 90px;
        padding: 0 30px;
        background-color: #FFF;
        box-shadow: none;

        .nav-wrapper {
            @include d-flex-center;
            justify-content: space-between;
            position: relative;

            .brand-logo {
                position: inherit;
                display: flex;
            }

            #nav-mobile {
                height: 100%;
                @include d-flex-center;

                li {
                    height: 100%;
                    @include d-flex-center;

                    a {
                        height: 100%;
                        color: $font_blue;
                        text-transform: uppercase;
                        font-size: 12px;
                        letter-spacing: 2px;
                        @include d-flex-center;
                        position: relative;
                        
                        &:before,
                        &:after {
                            content: '';
                            position: absolute;
                            bottom: 0px;
                            left: 0; right: 0;
                            height: 2px;
                            background-color: $back_blue;
                        }
                        &:before {
                            opacity: 0;
                            transform: translateY(- 8px);
                            transition: transform 0s cubic-bezier(0.175, 0.885, 0.320, 1.275), opacity 0s;
                        }
                        &:after {
                            opacity: 0;
                            transform: translateY(8px/2);
                            transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.320, 1.275), opacity 0.2s;
                        }
                        &:hover, &:focus {
                            &:before, &:after {
                                opacity: 1;
                                transform: translateY(0);
                            }
                            &:before {
                                transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.320, 1.275), opacity 0.2s;
                            }
                            &:after {
                                transition: transform 0s 0.2s cubic-bezier(0.175, 0.885, 0.320, 1.275), opacity 0s 0.2s;
                            }
                        }
                    }

                    a:hover {
                        background-color: inherit;
                    }
                    .connect-button {
                        background-color: $back_blue;
                        color: $font_white;
                        transition: all 0.5s;

                        &:after, &:before {
                           display: none!important;
                        }
                    }
                    .connect-button:hover {
                        background-color: $back_turcoise!important;
                        transition: all 0.5s;
                    }
                    .disconnect-button {
                        background-color: $font_turcoise!important;
                        color: $font_white;
                        transition: all 0.5s;
                    }
                    .disconnect-button:hover {
                        background-color: $back_blue!important;
                        transition: all 0.5s;
                        
                        &:after, &:before {
                            display: none!important;
                        }
                    }
                } 
                .active {
                    background-color: inherit;

                    a {
                        color: $font_blue;
                        font-weight: 700;

                        &:after {
                            opacity: 1;
                        }
                        &:before {
                            display: none;
                        }
                    }

                }
            }
        }
    }

    .sidenav-trigger {
        display: none;
        margin: 30px 0 0 20px;

        .material-icons {
            color: $back_blue;
            font-size: 35px!important;
        }
    }
}