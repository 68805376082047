/*-----------------
------Buttons------
------------------*/
.link-around {
    color: $font_blue;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    
    i.right {
        margin-left: 15px;
        background: #013767;
        color: white;
        border-radius: 5px;
        padding: 4px;
        font-size: 20px;       
        margin: 0 10px 0 0 ;
    }
}

.link-around-b {
    color: $font_blue;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    
    i.right {
        margin-left: 15px;
        background: none;
        color: $font_blue;
        border-radius: 5px;
        padding: 4px;
        font-size: 20px;       
        margin: 0 10px 0 0 ;
    }
}
.btn-blue-square {
    height: 28px;
    width: 28px;
    background-color: $back_blue;
    color: $font_white;
    border-radius: 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 30px;
    margin-right: 10px;
    box-shadow: 0px 16px 21px -3px rgba(0, 19, 166, 0.13);
    
    img {
        width: 28px;
    }

    @media screen and (max-width:980px){
        height: 25px!important;
        width: 25px!important;
        img {
            width: 24px!important;
        }
    }
}


// Btn connexion
.connect-btn {
    width: 90%;
}


// Scroll Progress.
// ---------------
.container_scrollbar {
    position: fixed;
      bottom: 20px;
      right: 20px;
      height: 40px;
      width: 40px;
      background: transparent;
      border-radius: 50%;
  
    .container_scrollbar_rel {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
  
        img {
            width: 33px;
        }

        .hidden_arrow {
            visibility: hidden;
            opacity: 0;
            transition: all 0.5s;
        }
        .scroll-progress-circle {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 10;
            width: 40px;
            height: 40px;
            fill: none;
            stroke: var(--scroll-progress-circle-color, $font_turcoise);
            stroke-width: 3px;
            stroke-dashoffset: 125.6811294555664;
            stroke-dasharray: 125.6811294555664;
            background-color: transparent;
            border-radius: 50%;
        }
        
    }
}

//tooltip on hover

.tooltip {
    position: relative;
    display: inline-block;
}
  
.tooltip .tooltiptext {
visibility: hidden;
width: 130px;
font-size: 0.8rem;
background-color: black;
color: #fff;
text-align: center;
border-radius: 6px;
padding: 5px 5px;

/* Position the tooltip */
position: absolute;
z-index: 1;
top: -20px;
left: 25%; 
}

.tooltip:hover .tooltiptext {
visibility: visible;
}