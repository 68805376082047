.container_mission {
    .container_information_mission {
        padding: 0!important;
        margin-bottom: 23px!important;
        color: $font_blue!important;
    
        .mission_subtitle {
            margin: 0;
            padding: 10px 0;
            font-size: 15px;
            font-weight: 700;
            line-height: 20px;
            color: $font_blue!important;
            text-transform: uppercase;
        }
        .mission_information_container {
            padding: 10px 0;

            .mission_information_container_line { 
                margin-bottom: 5px;

                .mission_information {
                    width: 100%;
                    display: inline-block;
                    color: $font_blue;
                }
                .mission_information_data {
                    display: inline-block;
                    width: 100%;
                    text-align: end;
                    color: $font_blue;
                    border-bottom: 1px solid $back_blue;
                    padding-bottom: 5px;

                    @media only screen and (max-width: 992px) {
                        width: 100%;
                    }
                }
            }
        }
    }
    .mission_subtitle {
        margin: 0;
        padding: 10px 0;
        font-size: 15px;
        font-weight: 700;
        line-height: 20px;
        color: #013A65 !important;
        text-transform: uppercase;
    }
}

.tabs {
    .tab {
        a{
            color: $font_blue!important;
        }
        a.active {
            color: $font_blue!important;
            background-color: #9e9e9e38!important;
        }
    }
    .indicator {
        background-color: $back_blue!important;
    }
}

.card_mission {
    .card_mission_title {
        color: $font-blue;
    }
    .card_mission_date {
        color: $font-blue;
    }
    .card_mission_client {
        color: $font-blue;
    }
    .card_mission_price {
        margin: 20px 0!important;
        color: $font-blue!important;
        font-size: 1.5rem;
    }
    .card_mission_recap {
        color: $font_turcoise!important;
    }
    .right_part {
        margin-top: 1.52rem;

        .status_mission {
            padding-left: .75rem;

            .dot {
                height: 10px;
                width: 10px;
                margin-right: 10px;
                border-radius: 50%;
                display: inline-block;
            }
        }
        .color-grey {
            color: grey!important;
        }
        .color-orange {
            color: orange!important;
        }
        .color-green {
            color: green!important;
        }
        .color-red {
            color: red!important;
        }
        .circle-grey {
            background-color: grey;
        }
        .circle-orange {
            background-color: orange;
        }
        .circle-green {
            background-color: green;
        }
        .circle-red {
            background-color: red;
        }
        a {
            height: auto;
            line-height: 20px;
            padding: 5px;
            width: 100%;
            margin-top: 10px!important;
            margin-bottom: 10px!important;
        }
        .mission_raison_annulation {
            height: 140px;
            padding: 5px;
            overflow: scroll;
            background: #f2f9ff;
            border-radius: 7px;
        }
    }
}
.card_mission:not(:last-child) {
    border-bottom: 1px solid #013767;
}


.mission_change_date {
    .recapitulatif_mission {
        margin-bottom: 30px;
        color: $font_blue;
        
        p {
            text-decoration: underline;
            font-weight: 600;
            font-size: 1.3rem;
        }
    }
    .information {
        margin-bottom: 30px;
        background:$back_turcoise;
        border-radius:5px;
        padding:15px;
        color:#FFF;
        font-weight:600;
    }
}