#main-logo {
    width: 230px;

    @media screen and (max-width: 430px) {
        width: 200px;
    }
    @media screen and (max-width: 350px) {
        width: 200px;
    }
    @media screen and (max-width: 330px) {
        width: 150px;
    }
}

#footer-logo {
    width: 100px;
}