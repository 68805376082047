.card_container {
    height: 350px;
    margin: 10px 0;

    .dashboard_card {
        height: 100%;
        border-radius: 5px;
        transition: all 0.4s;
        background-size: cover!important;
        position: relative;

/*         &.skipper-card {
            background: url('/images/img_avatar.png') no-repeat center;
        }

        &.annonce-card {
            background:url('/images/image-bateau.jpg') no-repeat center;
        } */

        .dashboard_card_content {
            margin: inherit!important;
            position: absolute;
            bottom: 0;
            z-index: +1;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            color: $font_white;
            padding: 10px;
            min-height: 40%;

            .card_date {
                p {
                    margin: 5px 0;
                    line-height: 20px;
                    b {
                        font-weight: 400;
                        font-size: 1.7rem;
                    }
                }
            }
            .card_informations {
                border-left: 1px solid;

                .card_title {
                    font-size: 2rem;
                    margin: 0 0 10px 0;
                    line-height: 30px;
                }
                .card_location {
                    margin: 0!important;
                    font-weight: 600;

                    i {
                        vertical-align: middle;
                        font-size: 18px;
                    }
                }
                .card-type {
                    margin: 4px 0;
                }
            }
            .card_skipper_info {
                p {
                    margin: 0 0 10px 0;
                }
                .card_skipper_name {
                    font-weight: 600;
                    font-size: 2rem;
                    text-transform: uppercase;
                }
            }
        }
    }
    .filter_div_color {
        background-color: $back_blue;
        opacity: 0.6;
        height: 350px;
        transform: translateY(-350px);
        transition: all 0.4s;
        border-radius: 5px;
    }
}
.card_container:hover {
    .dashboard_card {
        transform: scale(1.05);
        box-shadow: 0 7px 13px rgba(0,0,0,.4);
        transition: all 0.4s;
        z-index: +1;

        .dashboard_card_content {
            background-color: #013767a1;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }

    }
    .filter_div_color {
        transform: translateY(-350px) scale(1.05);
        opacity: 0.2;
        transition: all 0.4s;
    }
}
//module de recherche
input[type=text]:not(.browser-default):focus:not([readonly]) {
    border-bottom: 1px solid $font_blue!important;
    box-shadow: 0 1px 0 0 $font_blue!important;
}
.dropdown-content li>span {
    color: $font_blue!important;
}
.select-wrapper input.select-dropdown:focus {
    border-bottom: 1px solid $font_blue!important;
}
//pagination
.pagination li.active {
    background-color: $back_turcoise!important;
}