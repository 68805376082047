.container_skipper {
    padding: 0 60px!important;
    @media screen and (max-width: 350px){
        padding: 0 20px!important;
    }
    .header_profil {
        position: relative;

        .image_profil {
            border-radius: 50%;
            height: 24vw;
            width: 24vw;
            background-size: cover!important;
            margin: 20px auto 20px auto;
            position: relative;
            min-width: 200px;
            min-height: 200px;
        }
        .line_under_image {
            position: absolute;
            top: 50%;
            z-index: -1;
            width: 100%;
            margin: 0 auto;
            border-color: $back_blue;
            opacity: 0.6;
        }
    }
    
    .name_profil {
        color: $font_blue;
        font-weight: 600;
        font-size: 1.5rem;
        text-align: center;
    }
    .experience_profil {
        color: $font_blue;
        font-weight: 400;
        font-size: 1rem;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 2px;

        .cv_link {
            margin-top: 30px!important;
            a {
                color: $font_blue;
                font-weight: 600;
                border: 1px solid $font_blue;
                padding: 8px;
                margin-top: 34px!important;
                font-size: 0.8rem;
                transition: all 0.3s;
            }
            a:hover {
                color: $font_white!important;
                background: $back_turcoise!important;
                border: 1px solid $font_turcoise;
                transition: all 0.3s;
            }
        }
    }
    .description_profil {
        color: $font_blue;
        font-weight: 400;
        font-size: 1rem;
        text-align: center;
        margin: 40px 0 50px 0;
    }
    .container_picto_profil {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;

        .container_picto_profil_sub {
            display: flex;
            align-items: center;
            margin: 0 0 20px 0;

            .picto_profil_circle {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background: #FFF;
                box-shadow: 0px 16px 21px -3px rgba(0, 19, 166, 0.13);
                z-index: 2;
    
                img {
                    height: 56px;
                }
            }

            .title_profil_sub {
                color: $font_blue;
                text-transform: uppercase;
                letter-spacing: 1px;
                margin-left: 10px;
                font-size: 0.8rem;
                font-weight: 600;
            }
        }
        span {
            color: $font_blue;
        }
    }
    .espace_between_button {
        display: flex;
        justify-content: space-around;

        @media screen and (max-width: 600px){
                margin: 15px 0!important;
        }
    }


}