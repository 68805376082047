.estimate_new {
    .tbody_blue {
        color: $font_blue;
        td {
            width: 50%;
            text-align: right;
        }

        @media screen and (max-width:530px) {
            tr {
                display: flex;
                flex-direction: column;

                th {
                    padding-bottom: 5px;
                }
                td {
                    padding-top: 0px;
                    width: 100%!important;
                    text-align: left;
                }
            }
        }

    }
    a.link-around-b {
        margin: 50px 0;
    }
}

//vue d'un devis proposé
.container_show_estimate {
    color: $font_blue;

    .information_devis {
        color: #a2a2a2;
    }  

    .estimate_mission {
        margin-bottom: 50px;

        .table_information_header {
            background: #dedede;
            color: black!important;
            font-weight: 400;
            padding: 15px;
        }

        td {
            text-align: right;
        }
        .price_line {
            border-bottom: none!important;

            .price_ttc {
                font-weight: 600;
            }
        }

    }
    .container_action_button {
        display: flex;
        justify-content: space-around;
        align-items: center;

        @media screen and (max-width:500px) {
            flex-direction: column;
        }
        @media screen and (max-width:300px) {
            form {
                text-align: center;
                margin-top: 30px;  
            }
        }

        
    }
}