#contact_form {
    form {
        padding-bottom: 40px;
        .form_col_full {
            overflow: hidden;
        }
        .row {
            margin-bottom: 0;
            margin-top: 0;
        }
        .input-field>label {
            color: $font_blue;
        }
        input:focus:not([readonly]) {
            border-bottom: 1px solid $font_blue!important;
            box-shadow: 0 1px 0 0 $font_blue!important;
        }
        textarea:focus {
            border-bottom: 1px solid $font_blue!important;
            box-shadow: 0 1px 0 0 $font_blue!important;
        }
        .required.active {
            color: $font_blue!important;
        }
        .input-field {
            #contact_message {
                min-height: 150px!important;
            }
        } 
        textarea.materialize-textarea {
            border:inherit !important;
            border-bottom:1px solid #9e9d9e !important;
        }
        ul {
            margin: 0 0 10px 0;

            li.error {
                color: #8ad2da!important;
                font-weight: 600!important;
            }
        }
        button.link-around-b {
            background: none!important;
            border: none!important;
            cursor:pointer;
        } 
        a#ContactFormCaptcha_SoundLink {
            display: none!important;
        }
        @media screen and (max-width: 600px) {
            div#ContactFormCaptcha_CaptchaDiv {
                display: flex;
                flex-direction: column;
            }
            input#captchaCode {
                margin-top: 30px;
            }
            img#ContactFormCaptcha_CaptchaImage {
                width: 48vw;
            }
        }

    }
    
}

input[type=email],
input[type=password],
input[type=date],
input[type=datetime],
input[type=tel],
input[type=number],
textarea.materialize-textarea,
input[type=text] {
    color: $font_blue!important;
}   
input[type=email]:after,
input[type=password]:after,
input[type=date]:after,
input[type=datetime]:after,
input[type=tel]:after,
input[type=number]:after,
textarea.materialize-textarea:after,
input[type=text]:after {
    color: $font_blue!important;
}   
input[type=email]:focus,
input[type=password]:focus,
input[type=date]:focus,
input[type=datetime]:focus,
input[type=tel]:focus,
input[type=number]:focus,
textarea.materialize-textarea:focus,
input[type=text]:focus {
    border-bottom: 1px solid $font_blue!important;
    box-shadow: 0 1px 0 0 $font_blue!important;
}   
input.valid[type=text]:not(.browser-default) {
    border-bottom: 1px solid $font_blue!important;
    box-shadow: 0 1px 0 0 $font_blue!important;
}
label.required {
    color: $font_blue!important;
}
label.active {
    color: $font_blue!important;
}
[type="checkbox"]:checked+span:not(.lever):before {
    border-right: 2px solid $font_blue!important;
    border-bottom: 2px solid $font_blue!important;  
}

button.link-around {
    background: none!important;
    border:none!important;
    padding: 0!important;
    margin: 30px 0;
    cursor:pointer;
    font-family: $font-stack!important;
}

.file-field.input-field .btn {
    background-color: $back_blue!important;
}

.save-btn {
    background-color: $back_blue!important;
}

textarea.materialize-textarea {
min-height: 200px!important;
border: 1px solid #9e9d9e!important;
}

form {
    li.error {
        color: $font_turcoise!important;
        font-weight: 600!important;
    }
}