.error_container {
    height: calc(100vh - 90px);
    @include d-flex-center;
    flex-direction: column;
    justify-content: center;

    h1 {
        font-weight: 700;
        text-transform: uppercase;
    }

    h2 {
        text-align: center;
        font-size: 2rem;
        display: flex;
        flex-direction: column;
        
        a {
            font-size: 2rem;
            color: $font_turcoise!important;
            transition: color 0.5s;
        }
        a:hover {
            color: $font_blue!important;
            transition: color 0.5s;
        }
    }
}