/*-----------------
------Element------
------------------*/
//numéro comment ça marche
.number_explain {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    
    @include d-flex-center;
    justify-content: center;
    font-size: 30px;
    box-shadow: 0px 16px 21px -3px rgba(0, 19, 166, 0.13);
}
.white-version {
    background: $back_white;
    color: $font_blue;
}
.blue-version {
    background: $back_blue;
    color: $font_white;
}

//line-between-number
.line-between-number {
    position: absolute;
    border: 0.5px solid black;
    margin-top: 130px;
    margin-left: calc(0.75rem + 30px);
    height: calc(100% - 250px);
}



/*Main*/
.main {
    .introduce {
        height: auto;
        min-height: calc(100vh - 90px);
        @include d-flex-center;
        padding: 0 60px;
        max-width: 1600px;

        .illustration-circle {
            position: relative;
            height: 400px;
            width: 400px;
            margin: 0 auto;
            border-radius: 50%;
            background-color: $back_blue;

            img {
                position: absolute;
                width: 117%;
                left: -7%;
                top: -14%;
            }
        }

        .introduce-content {
            .introduce-content-title {
                color: $font_blue;
                font-weight: 700;
                font-size: 40px;
                margin: 0;
            }
            .introduce-content-text {
                color: $font_blue;
                font-weight: 400;
                font-size: 15px;
                margin: 35px 0;
                line-height: 26px;
            }
        }
    }

    .operation {
        background-color: $back_white;
        border-radius: 300px;
        max-width: 1400px;
        margin: 0 auto;

        &_explain {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 60%;
            margin: 0 auto;
            padding: 80px 0;

            @media screen and (max-width: 660px) {
                width: 80%;
            }
            
        
            &_container {
                @include d-flex-center;
                color: $font_blue;
                margin: 30px 0;
                z-index: 2;

                h4.operation_explain_container_title.col {
                    margin: 0!important;
                    font-size: 23px;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    font-weight: 600;
                    text-align: center;
                }

                &_text {
                    letter-spacing: 1px;

                    span {
                        font-weight: 700;
                    }
                }
            }

            .third-title {
                margin: 45px auto 30px auto
            }
            .link-around {
                margin: 30px 0;
                padding: calc(0.75rem + 13px) ;
                z-index: 2;
            }
        }

        &_skipper {
            @include d-flex-center;
            flex-direction: column;
            width: 60%;
            margin: 0 auto;
            padding: 20px 0 80px 0;

            &_header {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                .third-title {
                    margin: 0;
                }
            }

            &_display {
                margin: 40px 0;
                width: 100%;
                display: flex;
                justify-content: center;
                
                .link-around {
                    margin: 20px auto!important;
                }

                .skipper_card_introduce {
                    background-size:cover!important; 
                    height:30vw!important;
                    max-height: 420px;
                    width:19vw!important;
                    max-width: 250px;
                    border-radius:5px;
                    cursor: pointer;
                    /* filter: opacity(0.5); */
                    /* transition: filter 0.5s; */
                    position: relative;
                }
                .skipper_card_introduce:nth-child(2n) {
                    margin: 0 auto;
                }
                .card-title {
                    position: absolute;
                    bottom: 0;
                    padding: 20px;
                    .card-title-name{
                        color:$font_white;
                        font-weight: 700;
                        text-transform: uppercase;
                        display: block;
                    }
                    .card-title-city{
                        color:$font_white;
                        font-weight: 400;
                        display: block;
                    }
                }
                /* .skipper_card_introduce:hover {
                    filter: opacity(1);
                    transition: filter 0.5s;
                } */
                @media screen and (max-width: 750px) {
                    flex-direction: column;
                    .skipper_card_introduce {
                        margin: 15px auto;
                        height: 33vw !important;
                        width: 40vw !important;

                        @media screen and (max-width: 500px) {
                            height: 50vw !important;
                            width: 50vw !important;
                        }
                    }
                }
            }
        }
        
    }

    .explanation {
        @include d-flex-center;
        flex-direction: column;
        padding: 100px 80px;
        position: relative;
        max-width: 1500px;
        margin: 0 auto;

        .line_advantage {
            position: absolute;
            width: calc(100% - 160px);
            color: $back_blue;
            background-color: $back_blue;
            border-color: $back_blue;
            border: 0.5px solid;
            margin: 30px auto;
        }

        &_text {
            margin: 30px 0;
            color: $font_blue;
            text-align: center;
        }

        .explanation_advantage {
            @include d-flex-center;
            flex-direction: column;

            &_picto_container {
                @include d-flex-center;
                justify-content: center;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background: $back_white;
                box-shadow: 0px 16px 21px -3px rgba(0, 19, 166, 0.13);
                margin-bottom: 30px;
                z-index: 2;

                .explanation_advantage_picto {
                    height: 56px;
                }
            }


            &_title {
                font-weight: 600;
                font-size: 19px;
                line-height: 21px;
                color: $font_blue;
                text-transform: uppercase;
                text-align: center;
            }
            &_text {
                color: $font_blue;
                margin: 30px;
                text-align: center;
            }
        }
        #slides {
            display: none;
        }
    }
}
@media screen and (max-width: 1050px) {
    .operation_skipper_header {
        flex-direction: column;
        align-items: flex-start!important;

        a.link-around {
            margin-top: 10px;
        }
    }
}

@media screen and (max-width: 990px) {
    .main {
        .introduce {
            flex-direction: column-reverse!important;
            height: auto!important;
    
            .introduce-content { 
                margin: 90px 0;
    
                .introduce-content-title {
                    font-size: calc(3vw + 20px);
                    text-align: center;
                    @media screen and (max-width: 500px) {
                        font-size: calc(3vw + 24px);
                    }
                }
                .introduce-content-text {
                    text-align: center;
                }
    
                .link-around {
                    justify-content: center;
                }
            }
    
            .introduce_illustration {
                margin: 0 0 90px 0;
            }
            
        }
        .operation {
            border-radius: 150px!important;
    
            .operation_explain {
                padding: 80px 0 20px 0;
                @media screen and (max-width: 450px) {
                    padding: 40px 0 20px 0;
                }

                .line-between-number {
                    display: none;
                }
                .operation_explain_container {
                    flex-direction: column;
                    justify-content: center;
                    margin: 20px 0;
                    
                    .operation_explain_version_container {
                        margin: 0 auto 20px auto!important;
                    }
                    .operation_explain_container_title {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                    }
                    .operation_explain_container_text {
                    width: 100%;
                    display: block;
                    text-align: center;
                    }
                }
    
                .link-around {
                    margin: 20px auto!important;
                }
            }
        }
        .explanation {
            padding: 100px 10px;
            .line_advantage {
                width: calc(100% - 80px);
                margin: 30px 20px;
            }
            .explanation_advantage_text {
                margin: 15px;
            }
        }
    }
}

@media screen and (max-width: 660px) {
    .main {
        .introduce {
            padding: 0 35px!important; 

            .introduce_illustration {
                .illustration-circle {
                    height: 45vw;
                    width: 45vw;
                }
            }
            .introduce-content {
                padding-left: 0;
                padding-right: 0;

                h1.introduce-content-title {
                    font-size: 20px;
                }
            }
        }
       .operation {
            border-radius: 80px!important;

            .operation_explain {
                width: 80%!important;
            }
        }
        .explanation {
            .row {
                display: none;
            }
            #slides {
                display: inherit;
                position: relative;
                width: 100%;        
                height: 250px;
                padding: 0px;
                margin: 0 0 20px 0;
                list-style-type: none;
                @media screen and (max-width:450px) {
                    margin: 0 0 40px 0;
                }

                .controls{
                    position: absolute;
                    top: 40%;
                    cursor: pointer;
                    background: transparent;
                    z-index: 3;
                    border:none;
                    border-radius: 50%;
                }
                #previous {
                    left:0;
                }
                #next {
                    right:0px;
                }
                  
                .slide {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    margin: 0 auto;
                    height: 100%;
                    width: 100%;
                    opacity: 0;
                    -webkit-transition: opacity 1s;
                    transition: opacity 1s;



                    #slide_move {
                        width: 50%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        text-align: center;
                        margin: 0 auto;

                        @media screen and (max-width:400px) {
                            width: 80%;  
                        }

                        .explanation_advantage_picto_container {
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
                }
                .showing {
                    opacity: 1;
                }
                

            }
            
        }
    }
}

@media screen and (max-width: 600px) {
    .main {
        .explanation {
            .explanation_advantage {
                height: 250px;
            }
            .line_advantage {
                display: none;
            }
        }
        .operation_skipper {
            width: 80%;
        }
    }
}

@media screen and (max-width: 450px) {
    .main {
        .introduce {
            .introduce-content {
                margin: 60px 0;
            }
            .introduce_illustration {
                margin: 0 0 60px 0!important;

                .illustration-circle {
                height: 60vw;
                width: 60vw;
                }
            }
        }

    }
}
