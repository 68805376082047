.container_boat {
    padding: 0 50px!important;

    .add_boat_button {
        margin-bottom: 30px;
        display: inline-flex;
        background: #FFF;
        transition: all 0.3s;
        border-radius: 6px;
        padding: 5px;
    }
    .add_boat_button:hover {
        background: #f2f9ff!important;
        transition: all 0.3s;
    }
    .boat_card_information {
        height: 250px;
        @include d-flex-center;
        margin-bottom: 40px;
        box-shadow: 0 3px 9px -1px rgba(1, 58, 101, 0.2);
        border-radius: 5px;
        
        @media screen and (max-width :600px){
            flex-direction: column;
        }

        .container_boat_image {
            height: 250px;
            background-size: cover!important;
            padding: 0;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;

            .filter_boat_image {
                background-color: $back_blue;
                height: 100%;
                width: 100%;
                opacity: 0.8;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }
        }
        .container_boat_info {
            height: 250px;
            padding: 20px!important;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            
            .container_info_name {
                font-size: 2rem;
                font-weight: 600;
                color: $font_blue;
            }
            .container_boat_info_header {
                font-size: 1.3rem!important;
                font-weight: 400!important;
                color: $font_blue;

                span {
                    display: inline;
                    text-transform: uppercase;
                }
                span:after {
                    content: " |";
                }
                span:last-child:after {
                    content: "";
                }
            }
            .container_boat_info_main { 
                span {
                    display: inline;

                    em {
                        font-style: inherit;
                        font-weight: 600;
                    }
                }
                span:after {
                    content: " |";
                }
                span:last-child:after {
                    content: "";
                }
            }

        }

    }
}