@charset 'UTF-8';

@import 'abstracts/variables',
        'abstracts/mixins';

@import 'base/base',
        'base/typography';

@import 'layout/footer',
        'layout/header',
        'layout/main',
        'layout/sidebar';

@import 'components/logo',
        'components/buttons',
        'components/form',
        'components/picker',
        'components/title';

// Pages
@import 'pages/home',
        'pages/error',
        'pages/help',
        'pages/mentions',
        'pages/profil',
        'pages/dashboard',
        'pages/boats',
        'pages/skipper',
        'pages/mission',
        'pages/ad',
        'pages/estimate',
        'pages/login';
