.main {
    flex: 1 0 auto;
    margin-top: 90px;
    background: #FFF!important;
    min-height: calc(100vh - 90px);

    .main-container-page {
        display: flex;
        position: relative;
        height: 100%;

        .container_page {
            width: calc(100vw - 280px); 
            max-width: 1300px;
            margin-left: auto;
            margin-right: auto;
            padding: 0 30px;
        }
    }
}


@media only screen and (min-width: 992px) {
    .main {
        display: flex;
        flex-direction: column;
    }
}
@media only screen and (max-width: 992px) {
    .main {
        .container_page {
            width: 100vw!important;
            transform: translateX(0px)!important;
        }
    }
}
@media only screen and (max-width: 850px) {
    .main {
        margin-bottom: 60px!important;
    }
}
@media only screen and (max-width: 400px) {
    .main {
        .container_page {
            padding: 0 15px!important;
        }
    }
}