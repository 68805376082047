#footer {
    flex-shrink: 0;
    z-index: +1;
    background: #FFF;

    .footer_navigation {
        @include d-flex-center;
        justify-content: space-around;
        color: $font_blue;
        max-width: 1500px;
        margin: 70px auto;
        background-color: $back_white;

        .collapsible-header {
            cursor: inherit;
            line-height: inherit;
            padding: inherit;
            border-bottom: inherit;

            @media screen and (max-width: 850px) {
                cursor: pointer;
                line-height: 1.5;
                padding: 1rem;
                border-bottom: 1px solid #ddd;
                justify-content: space-between;
                
                i {
                    display: flex;
                    align-items: center;
                }
                i::after {
                    content: "expand_more";
                }

            }
        }

        .collapsible-body {
            border-bottom: inherit;
            padding: inherit;
            @media screen and (max-width: 850px) {
                border-bottom: 1px solid #ddd;
                padding: 0 2rem 2rem 2rem;
            }
        }


        h5.footer_navigation_container_title {
            text-transform: uppercase;
            font-size: 15px;
            font-weight: 700;
            line-height: 20px;
        }
        .footer_navigation_container_list {
            li {
                margin: 5px 0;
                font-size: 13px;
                a {
                    color: $font_blue;
                }
                a:hover {
                    border-bottom: 1px solid;
                }
            }
        }
        .footer_navigation_container {
            .brand-logo {
                display: none;
                @media screen and (max-width: 850px) {
                    display: flex;
                    margin: 0 10% 0 0;
                }
            }
            h5 {
                display: none;
                @media screen and (max-width: 850px) {
                    display:flex;
                    text-transform: uppercase;
                    font-size: 15px;
                    font-weight: 700;
                    line-height: 20px;
                }
            }
            .footer_navigation_adresse {
                p {
                    font-size:13px!important;
                }
            }
        }
    }
    .footer-streamer {
        padding: 0 60px;
        @include d-flex-center;
        flex-direction: column;
        justify-content: center;
        height: 90px!important;
        background-color: $back_blue;
        color: #ffffff8a;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 2px;

        a{
            color: #ffffff8a;
            transition: color 0.5s; 
        }
        a:hover{
            color: $font_white;
            transition: color 0.5s; 
        }

        .footer-streamer-left {
            margin: 10px 0 5px 0;
            a::after {
                content: ' |';
                white-space: pre;
            }
            a:last-child:after {
              content: '';
            }
        }
        .footer-streamer-right {
            margin: 5px 0 10px 0;
            span::after {
                content: '|';
                white-space: pre;
            }
            span:last-child:after {
              content: '';
            }
            @media screen and (max-width: 680px) {
                a {
                    color:#FFF!important;
                }
            }
        }
    }
}

@media screen and (max-width: 1100px) {
    .footer-streamer-left {
        display: none;
    }
    .footer-streamer-right {
        margin: 0 auto!important;

        @media screen and (max-width: 680px) {
                display: flex!important;
                flex-direction: column!important;
                font-size: 10px;
        }
    }
}
@media screen and (max-width: 850px) {
    .footer_navigation {
        flex-direction: column;
        margin: 0 0!important;

        .brand-logo {
            display: none;
        }

        .container-accordion {
            width: 100%;
            margin: 0;
            padding: 0 4%;
            border: 0;
            box-shadow: none;

            .collapsible-header {
                h5 > br {
                    display: none;
                }
            }
            .active {
                .collapsible-header {
                    border-bottom: none!important;
                    border-color: transparent;

                   
                        i::after {
                            content: "expand_less"!important;
                        }
                    
                }
            }
        }
        
        .footer_navigation_container {
            margin: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
        
    }
}

@media screen and (max-width: 680px) {
    #footer {
        .footer-streamer {
            font-size: 10px!important;
            padding: 0 0px;
            @include d-flex-center;
            justify-content: center;
            text-align: center;
            height: 80px;

            .footer-streamer-right {
                span::after {
                    content: '';
                }
            }
        }
    }
}

