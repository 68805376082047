/*sidebar fixed ordinateur*/
#fixed-side {
    display: flex;
    flex-direction: column;
    width: 260px;
    min-width: 260px;
    height: calc(100vh - 110px);
    background: $back_sky;
    padding: 20px;
    /* position: fixed; */
    border-radius: 45px;
    margin: 0 20px;
    min-height: 767px;

    .left-sidebar-user {
        margin: 0 0 5px 0!important;
    }
    .sidebar-user-image {
        filter:  url(#bluefilter);
        background-size:cover!important;
        height: 220px;
        border-radius: 50%;
    }
    .sidebar-user-info {
        /* height: 100%; */
        text-align: center;
        margin-top: 20px;
        margin-bottom: 40px;

        p {
            font-weight: 700;
            font-size: 1.5rem;
            color: $font_blue;
            margin-bottom: 0px;
        }
        span {
            color:$font_turcoise;
            letter-spacing: 1px;
            text-transform: uppercase;
        }
    }
    .separator_side {
        display: flex;
        border-bottom: 0px;
        width: 85%;
        border-top: 1px #013a6430 solid;
    }

    .fixed-side-list {
        li {
            text-transform: uppercase;
            letter-spacing: 2px;
            font-weight: 400;
            margin: 20px 0;

            a {
                color: #01376799;
                transition: color 0.5s;
            }
            a:hover {
                color: $font_blue;
                transition: color 0.5s;
                font-weight: 700;
            }
        }
        .add_offer_impossible {
            .tooltip {
                a {
                    background-color: inherit!important;
                    border:none!important;
                    padding: inherit!important;
                    font-weight: 400!important;
                }
            }
        }
        .active {
            a {
                color: $font_blue!important;
                font-weight: 700!important;
            }
        }
    }
    .footer_sidebar {
        margin-top: 15px;

        a {
            font-size: 0.8rem;
        }
        a:hover {
            font-weight: 900;
            text-decoration-line: underline;
        }
    }
}
//met la sidebar en absolute quand on arrive au footer
.absolute-bottom {
    position: absolute!important;
    bottom: 20px!important;
}

//Sidebar Public
#slide-out {
    .sidenav_public {
        display: flex!important;
        flex-direction: column;
        align-items: center!important;

        .logo_sidebar {
            margin: 0 0 40px 0;
        }

        nav {
            background: #FFF;
            border: none;
            box-shadow: none;

            li {
                a {
                    color: $font_blue;
                    font-weight: 400;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                }

                .connect-button {
                    background : $back_blue;
                    color: $font_white;
                }
                .disconnect-button {
                    background : $font_turcoise;
                    color: $font_white;
                }
            }
        }
    }
}

//Sidebar registered
aside.not_home {
    hr {
        display: none;
    }
    .sidebar-user-info {
        a {
            color: $font_blue!important;
            text-transform: uppercase;
        }
        span {
            color: $font_blue!important;
        }
    }
    .fixed-side-list {
        li {
            a {
                text-transform: uppercase!important;
                letter-spacing: 2px;
                color: $font_blue!important;
            }
        }
        .add_offer_impossible {
            background: #dedede!important;

            .tooltip {
                padding: 0 32px;
                font-weight: 400;
                a {
                    padding: 0;
                    font-weight: 400!important;
                }
                .tooltiptext {
                    font-size: 1rem;
                    line-height: 30px;
                }
            }
        }
    }
    .footer_sidebar {
        display:none;
        i.material-icons.right {
            display: none;
        }
    }
    .not-home-list {
        border-top: 1px solid #80808052;
        margin: 0!important;
        li {
            margin: 0!important;

            a {
                text-transform: uppercase;
                letter-spacing: 1px;
                color: $font_blue!important;
            }
        }
        .logout_button {
            background-color: $back_turcoise!important;
            a {
                color: $font_white!important;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .sidenav-trigger {
        display: inherit!important;
        position: fixed;
        top: 0;
        margin: 18px 0 0 20px;
        color: #FFF;
    }
    #fixed-side {
        display: none;
    }
    #slide-out {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 40px 0;
        
        .left-sidebar-user {
            height: 100px;
            display: flex;
            align-items: center;

            .sidebar-user-image {
                background-size: cover!important;
                width: 150px;
                height: 100px;
                border-radius: 50%;
                filter:  url(#bluefilter);
            }
        }

        ul {
            li {
                margin: 20px 0;
            }
        }
        .slide-out-link-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 100%;
            padding: 0px 32px 20px 32px;

            a {
                margin: 6px 0;
            }
        }

    }
    
    /*Si la sidebar est ouverte :*/
    .sidenav-overlay {
        cursor: w-resize;
    }
    
}