.container_profil {
    background: #FFF;
    margin: auto 5%;
    margin-bottom: 0!important;
    
    .container_information_profil {
        padding: 0!important;
        margin-bottom: 23px!important;
    
        .profil_subtitle {
            margin: 0;
            padding: 10px 0;
            font-size: 15px;
            font-weight: 700;
            line-height: 20px;
            color: $font_blue!important;
            text-transform: uppercase;
        }
        .profil_information_container {
            padding: 10px 0;

            .profil_information_container_line { 
                margin-bottom: 5px;

                .profil_information {
                    width: 100%;
                    display: inline-block;
                    color: $font_blue;
                }
                .profil_information_data {
                    display: inline-block;
                    width: 100%;
                    text-align: end;
                    color: $font_blue;
                    border-bottom: 1px solid $back_blue;
                    padding-bottom: 5px;

                    @media only screen and (max-width: 992px) {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.edit_profil_cont {
    .action_container {
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: 650px) {
            flex-direction: column;
            align-items: flex-start;

            p.right-align {
                margin-top: 50px!important;
            }
        }
    }
}