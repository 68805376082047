.container_help {
    padding: 50px 80px;
    width: 100%;
    max-width: 1370;
    @media screen and (max-width: 550px) {
        padding: 80px 30px 80px 30px;
    }

    .container_help_left {
        padding: 0 50px 0 20px;
        @media screen and (max-width: 993px){
            padding: 0 50px 70px 50px;
        }
        @media screen and (max-width: 650px) {
            padding: 0 0px 70px 0px;
        }
        h1 {
            font-size: 2rem!important;
            color: $font_blue!important;
            font-weight: 600;

            @media screen and (max-width: 993px){
                text-align: center;
            }
        }
        .collapsible {
            border: none!important;
            box-shadow: none;

            .collapsible-header {
                line-height: 1.5;
                padding: 1rem 0;
                border-bottom: 1px solid #ddd;
                justify-content: space-between;

                h3 {
                    font-size: 15px;
                    font-weight: 600;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    color: $font_blue;
                }

                i {
                    display: flex;
                    align-items: center;
                }
                i::after {
                    content: "expand_more";
                }
            }

            .active {
                .collapsible-header {
                    border-bottom: none!important;
                    border-color: transparent;

                   
                        i::after {
                            content: "expand_less"!important;
                        }
                    
                }
            }

            .collapsible-body {
                border-bottom: 1px solid #ddd;
                padding: 2rem 2rem 2rem 2rem;
                color: $font_blue!important;
            }
        }
    }
    .container_help_right {
        padding: 0 50px 0 50px;
        background-color: $back_sky;
        border-radius: 50px;
        @media screen and (max-width: 380px){
            padding: 0 20px 0 20px;
        }
        .help_right_title {
            font-size: 2rem!important;
            font-weight: 600;
            color: $font_blue!important;

            @media screen and (max-width: 993px){
                text-align: center;
            }
        } 
    }
}