/*---------------
------Title------
----------------*/
.third-title {
    color: $font_blue;
    font-weight: 700;
    margin: 0 0;
    font-size: 35px;

    @media screen and (max-width: 660px) {
        font-size: 25px;
        text-align: center;
    }
}

.first_main_title {
    font-size: 2rem;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2px;
    color: $font_blue;
    margin: 80px 0;
}