
.container_mention {
    width: 80%;
    max-width: 1000px;
    margin: 0 auto;

    h1 {
        font-size: 2rem !important;
        font-weight: 700!important;
        color: $font_blue;
    }

    .second-title-mention {
        font-size: 1.5rem !important;
        font-weight: 700!important;
        color: $font_blue;
        margin-top: 4rem;
    }
    .number_title_mention {
        color: $font_turcoise!important;
    }
    .third-title-mention {
        font-size: 1rem !important;
        font-weight: 700!important;
        color: $font_blue;
    }
}

.container_csv {
    width: 80%;
    max-width: 1000px;
    margin: 0 auto;

    h1 {
        font-size: 2rem !important;
        font-weight: 700!important;
        color: $font_blue;
    }

    .second-title-mention {
        font-size: 1.5rem !important;
        font-weight: 700!important;
        color: $font_blue;
        margin-top: 4rem;
    }
    .third-title-mention {
        font-size: 1rem !important;
        font-weight: 700!important;
        color: $font_blue;
        margin-top: 4rem;
    }
}