.container_login {
    padding: 0 80px;
    max-width: 1200px;
    @media screen and (max-width: 550px){
        padding: 0 40px;
    }
    @media screen and (max-width: 410px){
        padding: 0 20px;
    }

    .container_login_left {
        background: $back_sky;
        border-radius: 50px;
        padding: 40px;

        @media screen and (max-width: 410px){
            padding: 20px;
        }

        .login_left_title {
            color: $font_blue;
            font-size: 2rem;
            font-weight: 600;
            text-align: center;
        }
        .login_description {
            text-align: center;
            color: $font_blue;
        }

        form {
            margin: 40px 0;

            input[type=email]:not(.browser-default):focus:not([readonly]),
            input[type=password]:not(.browser-default):focus:not([readonly]) {
                border-bottom: 1px solid $back_blue;
                box-shadow: 0 1px 0 0 $back_blue;
            }

            input#inputEmail {
                color: $font_blue!important;
            }
            
            .sub_log_form {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 20px 0 25px 0;

                span {
                    font-size: 0.8rem;
                }
                span:before { 
                    border-right: 2px solid $back_blue!important;
                    border-bottom: 2px solid $back_blue!important;
                }
                .login_mdp_missed {
                    font-size: 0.8rem;
                    color: #9e9e9e;
                }
            }
            button.link-around {
                background: $back_blue!important;
                color: $font_white!important;
                font-weight: 100!important;
                border-radius: 5px;
                padding: 10px 15px!important;
                margin: 40px auto;
                cursor: pointer;
                transition: all 0.3s; 
                border:none;
            }
            button.link-around:hover {
                background: $back_turcoise!important;
                transition: all 0.3s; 
            }
        }

        .separator_form {
            width: 50%;
            opacity: 0.5;
        }

        .login_social_container {
            margin: 40px 0;

            .login_social_text {
                color: $font_blue;  
                font-weight: 600;
                text-transform: uppercase;
            }   

            .btn-facebook {
                background: $back_blue!important;
                @media screen and (max-width:600px) {
                    margin-bottom: 10px;
                }
                
            }
            .btn-google {
                background: $back_turcoise!important;
            }
        }
    }
    .container_login_right {
        padding: 40px;
        .login_right_title {
            color: $font_blue;
            font-size: 2rem;
            font-weight: 600;
            text-align: center;
        }
        .login_description {
            text-align: center;
            color: $font_blue; 
        }
        .add_m {
            margin: 30px 0;
            font-weight: 600;
        }

        .container_register_button {
            margin-top: 30px;

            a {
                background:$back_blue;
                transition: all 0.5s;
                padding: 7px 20px;
                border-radius: 3px;
                text-transform: uppercase;
                color: $font_white;
                letter-spacing: 2px;
                margin: 0 auto;
            }
            a:hover {
                background:$back_turcoise!important;
                transition: all 0.5s;
            }
        }
    }
}

.container_register {
    background: $back_sky;
    border-radius: 80px;
    padding: 60px 7%;
    max-width: 800px;

    @media screen and (max-width: 650px){
        padding: 60px 6%!important;
    }

    form {

        label.required {
            color: $font_blue;
        }
        input:focus {
            color: $font_blue!important;
            border-bottom: 1px solid $back_blue!important;
            box-shadow: 0 1px 0 0$back_blue!important;
        }
        .active {
            color: $font_blue!important;
        }
        input.select-dropdown.dropdown-trigger {
            color: $font_blue;
        }
        .select-wrapper { 
            .dropdown-content li>span {
                color: $font_blue!important;
            }
        }
        
        a {
            color: $font_blue;
        }
        a:hover {
            font-weight: 600;
        }
        label span::before {
            color: $font_blue!important;
            border-right: 2px solid $font_blue!important;
            border-bottom: 2px solid $font_blue!important;
        }
        button.link-around-b {
            margin: 40px 0;
            border: none!important;
            background: none!important;
            padding: 0!important;
            cursor: pointer;
            transition: all 0.3s;
            font-family: $font-stack!important;
        }
        button.link-around-b:hover {
            font-weight: 900;
            transition: all 0.3s;
        }
        .has-error {
            margin-bottom: 0!important;

            .error {
                color: red!important;
            }
        }
    }
}

.completed_profil {
    background: $back_sky;
    border-radius: 80px;
    padding: 60px 15%;
    max-width: 900;

    @media screen and (max-width: 650px){
        padding: 60px 6%!important;
    }

    form {

        .btn {
            background-color: $back_blue!important;
        }
        label.required {
            color: $font_blue;
        }
        input[type=date]:not(.browser-default),  {
            color: $font_blue!important;
        }
        .input-field>label {
            color: $font_blue;
        }
        input:focus {
            color: $font_blue!important;
            border-bottom: 1px solid $back_blue!important;
            box-shadow: 0 1px 0 0$back_blue!important;
        }
        .active {
            color: $font_blue!important;
        }
        input.select-dropdown.dropdown-trigger {
            color: $font_blue;
        }
        .select-wrapper { 
            .dropdown-content li>span {
                color: $font_blue!important;
            }
        }
        
        a {
            color: $font_blue;
        }
        a:hover {
            font-weight: 600;
        }
        label span::before {
            color: $font_blue!important;
            border-right: 2px solid $font_blue!important;
            border-bottom: 2px solid $font_blue!important;
        }
        button.link-around-b {
            margin: 40px 0;
            border: none!important;
            background: none!important;
            padding: 0!important;
            cursor: pointer;
            transition: all 0.3s;
        }
        button.link-around-b:hover {
            font-weight: 900;
            transition: all 0.3s;
        }
        .has-error {
            margin-bottom: 0!important;

            .error {
                color: red!important;
            }
        }
        .link-around {
            margin-top:40px!important;
            padding: 5px!important;
            background: none!important;
            border:none;
            cursor:pointer;
        }
        .link-around:hover {
            background: #013a6421!important;
            border-radius: 6px;
        }
    }
}

//password oublié
.reinitialize_password {
    display: flex;
    align-items: center;
    height: 100%;

    .row .col.offset-m3 {
        margin-left: 0!important;

        .card-content {
            background: $back_sky;
        }
    }
}   