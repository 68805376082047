/*---------------------
------font colors------
---------------------*/
$font_blue: #013A65;
$font_white: #FFF;
$font_turcoise: #8ad2da;

/*-------------------
------font typo------
-------------------*/
$font-stack: 'Quicksand', sans-serif;


/*---------------------
------back colors------
---------------------*/
$back_white: #FFF;
$back_sky: #F2F9FF;
$back_blue: #013A65;
$back_turcoise: #8ad2da;


/*-----------*/
/*-- Custom --*/
/*-----------*/
.d-flex {
    display: flex;
} 
.dir-col {
    flex-direction: column;
}